/* eslint-disable */
import { trace } from './lib/trace.js'
import pkg from '../package.json'
trace.getResources('app-gateway')
import { getZtCoreVersion } from './lib/libs'
import './lib/vue-i18n.js'
import 'whatwg-fetch'
import 'custom-event-polyfill'
import 'core-js/stable'
import 'core-js/web/url'
import './lib/flutter'
import {
  loadMicroApp,
  registerMicroApps,
  runAfterFirstMounted,
  setDefaultMountApp,
  start,
  prefetchApps,
  addGlobalUncaughtErrorHandler
} from 'qiankun'
import { checkUserMobile } from './lib/device'
import './index.scss'
import './common.css'
import './theme.css'
import init from './lib/init'
import Login from './lib/login'
import Chat from './lib/Chat.js'

import { youMongEvent } from './lib/pv-event'
import getMLogInstance from './lib/mconsole'
import './lib/postmessage.js'
import {$t} from './lib/i18n'
import { isHideOtherDomain, compareVersions, showTips } from '@/lib/libs'
import './lib/service-worker.js'
const v = new Date().getTime()
window.__qiankun__ = {
  loadMicroApp,
  addGlobalUncaughtErrorHandler,
  v: v
}

let timer = null // 网络不佳定时器, 防止重复调用, 导致多次弹窗
console.log('version: ',pkg.version)
let loadOption = true 
if (checkUserMobile()) {
  // 生产环境再调用prefetch
  if (process.env.NODE_ENV !== 'development') {
    window.prefetchAppFn = prefetchAppFn
  }
  /**
   * 微服务加载状态
   * @param loading
   */
  const loader = (loading) => { 
    if (loading) {
      loadOption =  true
      // 8秒跳网络不佳, 20秒阻断交互
      if (window.flutter_inappwebview && getZtCoreVersion() >= 3) {
        timer = setTimeout(() => {
          if (loadOption) {
            window.flutter_inappwebview.callHandler('toast', JSON.stringify({ msg: '网络环境不佳'}))
            timer = setTimeout(() => {
              if (loadOption) {
                window.$tool.block()
              }
            },12000)
          }
        },8000)
      }
    } else {
      loadOption = false
      clearTimeout(timer)
    }
    document.getElementById('sub-loading').style.display = loading ? 'flex' : 'none'
  }
  const microAppRules = [
    '/mg/bi/subsidy',
    '/otapub',
    '/mg/otapub',
    '/otapubtrip',
    '/mg/otapubtrip',
    '/flight',
    '/mg/flight',
    '/mg/hotel',
    '/hotel',
    '/train',
    '/mg/train',
    '/car',
    '/mg/car',
    '/expense',
    '/mg/expense',
    '/subsidy',
    '/mg/subsidy',
    '/plan',
    '/mg/control/plan',
    '/reward',
    '/mg/oper/liquidation',
    '/appwxm',
    '/mg/home',
    '/home'
  ]
  // const gray_env = window.$tool.getCookie('gray_env') === '1' ? 1 : 0

  // 微应用配置
  function microOptions() {
    return location.origin
  }

  // prefetch延迟到进入/home 之后;所以挂载方法,之后调用
  function prefetchAppFn() {
    prefetchApps([
      {
        name: 'expense',
        entry: microOptions() + '/m/expense/index.html?_t=' + v
      },
      {
        name: 'common',
        entry: microOptions() + '/m/pp/common/index.html?_t=' + v
      },
      {
        name: 'flight',
        entry: microOptions() + '/m/flight/app/index.html?_t=' + v,
      },
      {
        name: 'hotel',
        entry: microOptions() + '/m/hotel/app/index.html?_t=' + v,
      },
      {
        name: 'train',
        entry: microOptions() + '/m/train/web/index.html?_t=' + v,
      },
      {
        name: 'car',
        entry: microOptions() + '/m/car/web/index.html?_t=' + v,
      }
    ], {
      fetch(url, ...args) {
        return window
          .fetch(url, {
            ...args,
            // mode: 'cors',
            // cache: 'force-cache'
          })
          .catch((err) => {
            console.error('子项目资源加载异常', url, err)
          })
      }
    })
  }
  prefetchApps([
    {
      name: 'app-common-plugin-footmenu',
      entry: '/m/platform/plugin/common/footmenu.html?t=' + v,
    },
    {
      name: 'home',
      entry: '/m/pp/home/index.html?_t=' + v,
    },
    {
      name: 'HomeQuickEntry',
      entry: '/m/bi/subsidy/app/HomeQuickEntry.html?_t=' + v,
    },
    {
      name: 'RecentItinerary',
      entry: '/m/bi/subsidy/app/RecentItinerary.html?_t=' + v,
    }
  ], {
    fetch(url, ...args) {
      return window
        .fetch(url, {
          ...args,
          // cache: 'force-cache'
        })
        .catch((err) => {
          console.error('子项目资源加载异常', url, err)
        })
    }
  })
  /**
   * Step2 注册子应用
   */
  registerMicroApps(
    [
      {
      name: 'app-common-plugin-footmenu',
        entry: '/m/platform/plugin/common/footmenu.html?t=' + v,
        container: '#subapp-viewport-footer',
        activeRule: (location) =>  [
            '/home',
            '/common/myOrder',
            '/common/journeyList',
            '/expense',
            '/index',
            '/common/mypage',
            '/common/management',
            '/mg/home',
            '/mg/common/myOrder',
            '/mg/common/journeyList',
            '/mg/expense',
            '/mg/index',
            '/mg/common/mypage',
            '/mg/common/management'
          ].includes(location.pathname)
      },
      {
        name: 'common',
        entry: microOptions() + '/m/pp/common/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: function (location) {
          for (let i = 0; i < microAppRules.length; i++) {
            if (location.pathname.startsWith(microAppRules[i])) {
              return false
            }
          }
          return true
        }
      },
      {
        name: 'home',
        entry: microOptions() + '/m/pp/home/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: ['/home', '/mg/home']
      },
      {
        name: 'otapub',
        entry: microOptions('otapub') + '/m/otapub/app/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: ['/otapub', '/mg/otapub']
      },
      {
        name: 'otapubtrip',
        entry: microOptions('otapubtrip') + '/m/otapub/trip/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: ['/otapubtrip', '/mg/otapubtrip']
      },
      {
        name: 'flight',
        entry: microOptions() + '/m/flight/app/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: ['/flight', '/mg/flight']
      },
      {
        name: 'hotel',
        entry: microOptions() + '/m/hotel/app/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: ['/hotel', '/mg/hotel']
      },
      {
        name: 'train',
        entry: microOptions() + '/m/train/web/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: ['/train', '/mg/train']
      },
      {
        name: 'car',
        entry: microOptions() + '/m/car/web/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: ['/car', '/mg/car']
      },
      {
        name: 'expense',
        //entry: 'https://expense.z-trip.cn/m/expense/index.html',
        entry: microOptions() + '/m/expense/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: ['/expense', '/mg/expense']
      },
      {
        name: 'subsidy',
        entry: microOptions() + '/m/bi/subsidy/app/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: ['/subsidy', '/mg/bi/subsidy']
      },
      {
        name: 'plan',
        entry: microOptions() + '/m/control/plan/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: ['plan', '/mg/control/plan']
      },
      {
        name: 'rewardPoints',
        entry: microOptions() + '/resource/rewardPoints/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: '/reward'
      },
      {
        name: 'liquidation',
        entry: microOptions() + '/m/oper/liquidation/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: '/mg/oper/liquidation'
      },
      {
        name: 'appwxm',
        entry: microOptions() + '/resource/appwxm/index.html?_t=' + v,
        container: '#subapp-viewport',
        loader,
        activeRule: '/appwxm'
      }
    ],
    {
      beforeLoad: [
        (app) => {
          if (window.moment) {
            window.moment.locale(localStorage.getItem('WEB_LOCALE_LANGUAGE'))
          }
          // 检测cookie, 如果cookie不一致,弹窗提示,点击确定后,刷新页面
          window.lazyInit && window.lazyInit()
          window.lazyInitMap && window.lazyInitMap()
          console.log('[LifeCycle] before load %c%s', 'color: green;', app.name)
          if (app.name === 'common') {
            trace.tag('LOAD_APP_COMMON')
          }
        }
      ],
      afterMount: [
        (app) => {
          loadOption = false
          if (app.name === 'common') {
            trace.tag('MOUNT_APP_COMMON_SUCCESS')
          }
          console.log('[LifeCycle] after mount %c%s', 'color: green;', app.name)
          setTimeout(() => {
            trace.getResources(app.name)
          }, 3000)
        }
      ],
      beforeMount: [
        (app) => {
          // 这里打点日志;如果cookie异常,需要上报一下
          var zt_verName =
            (window.zzzconfig && window.zzzconfig.getZatuVerName && window.zzzconfig.getZatuVerName()) || 'zaitu-ver'

          trace.log('app-beforeMount', {
            zaitu_ver: (window.$tool && window.$tool.getCookie(zt_verName)) || '',
            token: localStorage.getItem('token')
          })
          if (app.name === 'common') {
            trace.tag('MOUNT_APP_COMMON')
          }
          trace.getResources(app.name)
          console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
        }
      ],
      afterUnmount: [
        (app) => {
          console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
        }
      ]
    }
  )
  //设置默认进入的子应用
  setDefaultMountApp('/home')
  async function initApp() {
    console.log('[main.js]flutter------- initApp=====initAPP-')
    // 通知flutter开始加载h5
    try {
      window.flutter_inappwebview &&
        window.flutter_inappwebview.callHandler &&
        window.flutter_inappwebview.callHandler(
          'flutterReady',
          JSON.stringify({
            url: location.href
          })
        )
    } catch (err) {
      trace.log('FLUTTER', {
        data: {
          type: 'flutterReadyCallHandlerError',
          err: JSON.stringify(err)
        }
      })
      console.log('-errr', err)
    }

    console.log('[main.js] init --- cookie 版本更新')
    await init()
    getMLogInstance({ appProject: 'app-gateway' })
    // flutter 先暂不加入友盟统计
    await youMongEvent()

    // 1.处理默认登录逻辑
    const res = await Login()
    start({
      prefetch: false,
      sandbox: false,
      singular: false,
      getTemplate: (e) => {
        trace.log('loadapp', { data: e })
        return e
      },
      // prefetch: false,

      fetch(url, ...args) {
        return window
          .fetch(url, {
            ...args,
            // mode: 'cors',
            // cache: 'force-cache'
          })
          .catch((err) => {
            console.error('子项目资源加载异常', url, err)
          })
      }
    })
  }

  const inApp =
    navigator.userAgent.indexOf('APICloud') !== -1 ||
    window.location.search.indexOf('from=app') !== -1 ||
    window.flutter_inappwebview
  // console.log('navigator.userAgent', navigator.userAgent)
  let isAppReady = false
  if (inApp) {
    console.log('inApp======大', window.hasFlutterReady, compareVersions('3.5.0'))
    if (window.flutter_inappwebview) {
      window.flutterReady = function () {
        console.log('window.flutterReady-----333[main.js]setCookieFn====')
        trace.tag('FLUTTER', {
          data: {
            type: 'window.flutterReady_up_3.5.0',
            isAppReady,
            msg: isAppReady ? '已经初始化过,忽略' : '尚未初始化过'
          }
        })
        if (!isAppReady) {
          isAppReady = true
          initApp()
        }
      }
      if (compareVersions('3.5.0') < 0) {
        console.log('3.5.0之前的版本使用initApp')
        trace.tag('FLUTTER', {
          data: {
            type: 'window.flutterReady_down_3.5.0',
            isAppReady
          }
        })
        isAppReady = true
        initApp()
      } else if(self != top)  {
        console.log('iframe 内打开')
        trace.tag('FLUTTER',{
          data: {
            type: 'window.flutterInIframe',
            isAppReady
          }
        })
        isAppReady = true
        initApp()
      }    
    } else {
      window.apiready = initApp
    }
  } else {
    initApp()
  }

  function checkModuleSupport() {
    if ('supports' in HTMLScriptElement) {
      return HTMLScriptElement.supports('module')
    }
    return 'noModule' in document.createElement('script')
  }

  let isMoudleSupport = checkModuleSupport()

  console.log('isMoudleSupport', isMoudleSupport)

  runAfterFirstMounted(() => {
    console.log('runAfterFirstMounted')
    try {
      function checkEs6() {
        var value = false
        try {
          if (Array.isArray([])) {
            value = true
          }
        } catch (error) {
          console.log(error)
          return value
        }
        return value
      }

      function isModern() {
        'use strict'
        if (typeof Symbol == 'undefined') return false
        try {
          eval('class Foo {}')
          eval('var bar = (x) => x+1')
        } catch (e) {
          return false
        }
        return true
      }
      // 检测是否支持type = "module"
      window.mconsole &&
        window.mconsole.upYueYing(
          'Module_' + (isMoudleSupport ? 'true' : 'false') + (localStorage.getItem('IS_MODULE_SUP') || ''),
          {
            checkModuleSupport: isMoudleSupport
          },
          210,
          0,
          ['pp']
        )
      // 检测是否支持es6
      window.mconsole &&
        window.mconsole.upYueYing('Es6_' + (isModern() === true && checkEs6() === true ? '1' : '-1'), {}, 210, 0, [
          'pp'
        ])
    } catch (err) {
      console.error(err)
    }

    //挂载AI微应用
    Chat()

    if (!isHideOtherDomain()) {
      uploadTime()
    }

    setTimeout(function () {
      // 延迟一下再跳转
      // showTips('网站版本临时更新，如有打扰敬请谅解')
      let PC_COOKIE_INFO = sessionStorage.getItem("H5_COOKIE_INFO");
      console.log('PC_COOKIE_INFO', PC_COOKIE_INFO)
      if (PC_COOKIE_INFO) {
        console.log("提示客户端已经更新!");
        showTips($t('wang-zhan-ban-ben-lin-shi-geng'))
        sessionStorage.removeItem("H5_COOKIE_INFO");
      }
    }, 3000)
  })


  function uploadTime() {
    let fTime1 = localStorage.getItem('firstAppTime1')
    // if( fTime1 && ( window.api || window.flutter_inappwebview ) ){
    if (fTime1) {
      // let endTime = new Date().getTime()
      localStorage.removeItem('isFirstLoad')
      localStorage.removeItem('isFreshCookie')
      localStorage.removeItem('firstAppTime1')
    }
  }

  function refresh() {
    let reloadTimes = parseInt(sessionStorage.getItem('reloadTimes') || 0)
    if (reloadTimes > 1) {
      sessionStorage.setItem('reloadTimes', 0)
      if (!confirm($t('ying-yong-cheng-xu-ban-ben-yi-'))) {
        return
      } else {
        if (window.flutter_inappwebview) {
          window.flutter_inappwebview.callHandler('restartApp', JSON.stringify({}))
        }
      }
    }
    reloadTimes = parseInt(sessionStorage.getItem('reloadTimes') || 0)
    if (reloadTimes == 1) {
      sessionStorage.setItem('reloadTimes', reloadTimes + 1)
      setTimeout(() => {
        window.$ztDataAction &&
          window.$ztDataAction.push({
            action: 'aplus.record',
            arguments: ['RESOURCE_LOST_TWICE', 'CLK']
          })
      }, 0)
      setTimeout(() => {
        window.location.href = '/home'
      }, 200)
    } else {
      sessionStorage.setItem('reloadTimes', reloadTimes + 1)
      setTimeout(() => {
        window.$ztDataAction &&
          window.$ztDataAction.push({
            action: 'aplus.record',
            arguments: ['RESOURCE_LOST_ONE', 'CLK']
          })
      }, 0)
      setTimeout(() => {
        window.location.reload()
      }, 200)
    }
  }

  // 后续可增加日志上传功能
  addGlobalUncaughtErrorHandler((event) => {
    // 这里上报异常
    let args = []
    try {
      console.error('addGlobalUncaughtErrorHandler', event)
      let error = event && (event.error || event.reason)
      const errorName = 'Uncaught Error '
      args = [errorName, error]
      if (error instanceof Error) {
        args = [
          errorName,
          {
            name: error && error.name,
            message: error && error.message,
            stack: error && error.stack,
            filename: error && error.fileName,
            lineNumber: error && error.lineNumber,
            columnNumber: error && error.columnNumber
          }
        ]
        console.log('ERRR', args)
      }
      event && event.preventDefault()
    } catch (err) {
      console.log('addGlobalUncaughtErrorHandler err', err)
    }

    const reload =
      (event.type === 'error' &&
        event.error &&
        (event.error.message === 'ChunkLoadError' || event.error.message.includes('died'))) ||
      (event.type === 'unhandledrejection' &&
        event.reason &&
        typeof event.reason === 'object' &&
        event.reason.name === 'ChunkLoadError')
    console.error('----------reload', reload, event.filename)

    // alert(event.currentTarget)
    if (reload) {
      window.mconsole &&
        window.mconsole.upYueYing2(
          '网页连接超时',
          { filename: event.filename || '', message: (event.error && event.error.message) || '' },
          193,
          1
        )
      if (!window.isReloadPageLoading) {
        window.isReloadPageLoading = true
        refresh()
      } else {
      }
    }
  })
  /*全局处理promise未捕获异常*/
  window.addEventListener('unhandledrejection', (event) => {
    // 防止默认处理（例如将错误输出到控制台）
    event && event.preventDefault()
  })
}


// 代客下单多账户互串逻辑适配
const daikeIdCache = localStorage.getItem('daikeId')
if (daikeIdCache) {
  let temp = document.title
  document.addEventListener('visibilitychange', function() {
    var isHidden = document.hidden;
    if (isHidden) {
        temp = document.title
        document.title = `[代客] ${JSON.parse(localStorage.getItem('localUserInfo') || '{}').userName || ''} - `  + temp;
    } else {
        if (daikeIdCache !== localStorage.getItem('daikeId')){
          alert('当前用户已变更')
          window.location.reload()
        }
        document.title = temp;
    }
  });
}