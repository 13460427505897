import { trace } from '../trace'
import Base from '../Base'
import { getHttpInstance } from '../http'

import { defaultConfig, defaultTheme, isUAT, request, appendUAT, userState } from './basic'

export default class ZConfig {
  /** 数据仓库, */
  store = defaultConfig

  /** loading状态 */
  loading = false

  /**
   * 暂时兼容旧方案, 后续逐步清理
   * @returns Promise.resolve()
   */
  ready = () => Promise.resolve()
  /**
   * 切换uat路径
   * @param {string} url 路径
   * @returns string
   */

  isUAT = isUAT
  appendUAT = appendUAT

  /**
   * 获取主题ICON
   * @param {string} code 代码
   * @returns string
   */
  getThemeIcon = (code) => {
    let curTheme = this.store.currentTheme
    return curTheme == defaultTheme || curTheme === 'weishi' ? code : code + '-' + curTheme
  }
  /**
   * 获取登录服务域名
   * @returns string
   */
  getPassportUrl = () => {
    if (window.flutter_inappwebview) {
      return this.appendUAT(window.BASIC_CONF.SYS.DOMAIN_PASSPORT)
    }
    return this.appendUAT(this.getVal('DOMAIN_PASSPORT'))
  }

  /**
   * 获取接口域名
   * @returns string
   */
  getServerUrl = () => {
    return this.appendUAT(this.getVal('DOMAIN_MS'))
  }
  /**
   * 获取数据 纯展示
   */
  _getStore = () => {
    console.log(this.store)
    console.log(JSON.stringify(this.store))
  }

  /**
   * 获取一个是地址的配置，主要会封装uat参数
   * @param {string} key 键
   * @returns string
   */
  getUrl = (key) => {
    return this.appendUAT(this.getVal(key))
  }
  /**
   * 初始化应用信息
   */
  initAppInfo = () => {
    if (this.getVal('hasPartner')) {
      this.initTitle()
    }
  }
  /**
   * 切换主题
   * @param {string} theme 主题
   */
  initTheme = (theme) => {
    let currentTheme = theme || this.getVal('THEME') || defaultTheme
    this.store.currentTheme = currentTheme
    $tool.loadCSS('https://static.service.z-trip.cn/app/theme/' + currentTheme + '.css?v=4', 'theme')
  }

  /** 修改标题和 ICON */
  initTitle = () => {
    // 网页标题
    document.querySelector('title').innerHTML = this.getVal('NAME')
    // 网页ico
    if (!document.getElementById('z-icon')) {
      let iconDom = document.createElement('link')
      iconDom.id = 'z-icon'
      iconDom.rel = 'icon'
      iconDom.href = this.getVal('LOGO_ICO')
      document.querySelector('head').appendChild(iconDom)
    }
  }

  /**
   * 获取配置值
   * @param {string} key 键
   * @returns string
   */
  getVal = (key) => {
    return this.store[key]
  }

  /**
   * 获取当前用户信息
   * @returns Promise
   */
  getCurUserInfo = async () => {
    const userInfo = this.store.userInfo
    if (userInfo && userInfo.userId) {
      trace.tag('GET_BASICINFO', { data: userInfo })
      return userInfo
    }
  }

  /** 拼接数据, 重写userInfo */
  handleData = (store) => {
    const info = store.userInfo
    const user = store.userInfo.userDto

    if (typeof store['MAP_POSITIONING'] !== 'undefined') {
      localStorage.setItem('MAP_POSITIONING', store['MAP_POSITIONING'])
    } else {
      localStorage.removeItem('MAP_POSITIONING')
    }

    //TODO 暂时保留
    // ! 不可为所有人预订 && 不可为员工预订 && 不可为非员工预订 && 无特殊代订范围
    const i_only = !store.notLimit && !store.allowProxy && store.notAllowProxyNonEmp && !store.isHasSpecialBookers

    const userInfo = {
      userId: user.id,
      name: user.fullname,
      corpId: user.corpId,
      corpName: user.corpName,
      org: user.org,
      orgId: user.org ? user.org.id : user.orgId,
      orgName: user.org ? user.org.name : '',
      partnerId: user.partnerId,
      partnerName: user.org ? user.org.name : '',
      companyName: info.company,
      status: user.status,
      statusTxt: userState[user.status] || '',
      photoUrl: user.photo,
      mobileAreaCode: user.mobileAreaCode,
      mobile: user.mobile,
      companyPosition: user.title,
      email: user.email,
      cardType: user.userCert,
      country: user.country,
      countryCode: user.countryCode,
      birthday: user.birthDay,
      gender: user.gender,
      title: user.title,
      empCode: user.empCode,
      travelLevel: user.trLevel,
      firstName: user.firstName,
      lastName: user.lastName,
      rank: user.rank,
      rankName: user.rankName,
      sex: user.gender,
      vip: user.vip,
      hasApproveAuth: info.hasApproveAuth,
      hasReApproveAuth: info.hasReApproveAuth,
      // passengerSwitch: data.trainRule.passengerSwitch == 1,
      notLimit: store.notLimit,
      allowProxy: store.allowProxy,
      notAllowProxyNonEmp: store.notAllowProxyNonEmp,
      isHasSpecialBookers: store.isHasSpecialBookers,
      hideOrgSearch: store.hideOrgSearch,
      /*
          * 机票、火车符合差标是否选中且禁用
          * 机票条件：非客服代订&&非行政代订&&当前登录人职级设置为超标拒单&&不允许超标自付&&只能为自己预定时 || 强制选中且不可取消
          * 火车条件：非客服代订&&非行政代订&&当前登录人职级设置为超标拒单&&不允许超标自付&&只能为自己预定时
          ? IS_CUSTOMER 客服代订  【true/false】
          ? OTA_BOOKING_AGENT 行政代订  【true/false】
          ? flightOverproofSelfPay 国内机票是否支持超标自付 【true/false】
          ? intFlightOverproofSelfPay 国际机票是否支持超标自付 【true/false】
          ? trainOverproofSelfPay 火车是否支持超标自付 【true/false】
          ? i_only 只能为本人预订 【true/false】
          ? beyondTandardTravel 超标拒单 若给的文案包含拒绝订单则为超标拒单
          * */
      trainStandardIsDisabled: !store.IS_CUSTOMER && !store.OTA_BOOKING_AGENT && i_only,
      canSelUser: store.notLimit || store.allowProxy || !store.notAllowProxyNonEmp || store.isHasSpecialBookers
    }
    window.$CS?.setUnReadCSCount && window.$CS?.setUnReadCSCount(info.unReadCsMsg ? parseInt(info.unReadCsMsg) : 0)
    store.userInfo = userInfo

    if (store.TM_PARTNER_NAME) {
      store.NAME = store.TM_PARTNER_NAME
    }
    this.store = store
    this.initAppInfo()
    console.log('----ssss', this.store)
  }

  service = {
    /**
     * 获取系统配置
     * @param {string} baseURL 域名
     * @returns Promise
     */
    getSys: async (baseURL = defaultConfig.DOMAIN_MS) => {
      trace.tag('GATEWAY_GET_CONFIG_SYS')
      let res = {}
      console.log('----req', request)
      try {
        res = await request.get('api/pp/index/common/config/sys', {
          baseURL: appendUAT(baseURL),
          notNeedToken: true
        })
        trace.tag('GATEWAY_GET_CONFIG_SYS', { ...res })
        return res
      } catch (res) {
        return Promise.reject(res)
      }
    },
    /**
     * 获取用户信息
     * @param {string} baseURL 域名
     * @returns Promise
     */
    getUser: async (baseURL = defaultConfig.DOMAIN_MS) => {
      trace.tag('GATEWAY_GET_CONFIG_USER')
      let res = {}
      try {
        console.log('___BASE__', Base.get)
        res = await Base.get(
          'api/pp/index/common/config/user',
          {},
          {
            baseURL: appendUAT(baseURL)
          }
        )
        trace.tag('GATEWAY_GET_CONFIG_USER', { ...res })
        return res
      } catch (res) {
        return Promise.reject(res)
      }
    }
  }
  /**
   * 初始化操作, 如果检测到子系统则再次获取 sys 接口 (后续看可不可以直接优化)
   */
  initial = async () => {
    let store = {}
    const sys = await this.service.getSys()
    let sysData = { ...defaultConfig }
    /**
     * 这里有个坑, DOMAIN_MS预期的结果是不带protocol的,
     * 但是,axios请求里必须带 protocol 否则会造成路径异常,
     * 而默认的 DOMAIN_MS 是被 appendUAT 转换过的,可能会造成路径不匹配,
     * 为了保障basic内的 request 实例不被过度干涉, 因此先通过 appendUAT 进行转换再进行比对
     */
    const DOMAIN_MS = appendUAT(sys.data.DOMAIN_MS)
    if (sys.data.DOMAIN_MS && DOMAIN_MS !== defaultConfig.DOMAIN_MS) {
      const sys2 = await this.service.getSys(DOMAIN_MS)
      sysData = { ...sysData, ...sys2.data }
    } else {
      sysData = { ...sysData, ...sys.data }
    }
    this.store = sysData
    this.initTheme() // 初始化主题方案
    getHttpInstance() // 初始化 $httpUtil 对象 否则无法调用
    if (!localStorage.getItem('token')) {
      return
    }

    /** 登录后系统信息 */
    const user = await this.service.getUser(DOMAIN_MS)
    store = {
      ...sysData,
      ...user.data.partnerConfig,
      ...user.data.rankConfig,
      userInfo: user.data.useInfo
    }
    /** 数据适配, 部分数据需要进行上下文兼容性适配 */
    this.handleData(store)
    this.initTheme()
  }
  /**
   * 重置数据
   * @param {Function} callback 回调函数
   */
  reload = async (callback) => {
    await this.initial()
    callback &&
      callback({
        code: '0',
        data: this.store
      })
  }
}

window.$ZConfig = new ZConfig()
