import { trace } from './trace.js'
if ('serviceWorker' in navigator) {
  if (navigator.userAgent.toLocaleLowerCase().indexOf('ztflutter') >= 0) {
    navigator.serviceWorker.register('/service-worker.js?t=' + Date.now(), { scope: '/' })
    navigator.serviceWorker.addEventListener('message', (event) => {
      console.log('Service Worker 收到消息: ', event.data)
      if (event.data.type === 'CACHE_UPDATED') {
        // alert('缓存内容已更新')
        trace.tag('SERVICE_WORKER_CACHE_UPDATED', { data: event.data })
        try {
          if (!_.isEqual(JSON.parse(event.data.oldData), JSON.parse(event.data.newData))) {
            trace.tag('SERVICE_WORKER_CACHE_UPDATED', { data: event.data })
            location.reload()
          }
        } catch (e) {
          console.log('SERVICE_WORKER_CACHE_UPDATED_ERROR', e)
        }
      }
    })
  } else {
    caches.delete('api-cache').then(() => {
      navigator.serviceWorker.ready.then((registration) => {
        registration.unregister().then(() => {
          console.log('Service Worker 删除成功')
        })
      })
    })
  }
}
